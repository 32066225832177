import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import {FacilityChooserService} from '../../@core/facility-chooser.service';
import {toDateTime} from '../../utils/date.utils';

@Pipe({
    name: 'tzDate',
})
export class TzDatePipe implements PipeTransform {
    constructor(private datePipe: DatePipe,
                private fc: FacilityChooserService) {
    }

    transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
        return TzDatePipe.transform(this.fc, this.datePipe, value, format, timezone, locale);
    }

    static transform(fc: FacilityChooserService, datePipe: DatePipe, value: any, format?: string, timezone?: string, locale?: string): string | null {
        if (!value) return '';
        if (format != 'fromNow' && typeof value === 'string' && value.length < 11) return datePipe.transform(value, format, undefined, locale);
        timezone = timezone || toDateTime(value, {zone: fc.timezone}).toFormat('ZZZZ');
        if (format == 'fromNow') return toDateTime(value, {zone: fc.timezone}).toRelative();
        return datePipe.transform(value, format, timezone, locale);
    }
}
